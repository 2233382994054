import React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { getJwt, isLoggedIn, getUser } from "../services/auth"

class Emag extends React.Component {
  state = {
    Jwt: ""
  }

  componentWillMount = () => {
    this.getJwt()
  }

  getJwt = async () => {
    if (isLoggedIn()) {
      if (await getJwt(getUser().MemberNo, ({ data: { body } }) => {
        this.setState({
          Jwt: body
        })
      })) { }
    }
  }

  render = () => {
    const url = this.state.Jwt === "" ? "https://jrcea.libra.jpn.com/#/login" : process.env.SSO_LOGIN_URL + this.state.Jwt
    return (
      <>
        <Seo title="協会誌電子版" />
        <div id="pageTitle">
          <h1>
            協会誌電子版
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li>協会誌電子版</li>
          </ul>
        </div>

        <div id="main">
          <div className="contents">

            <section className="contents_block">
              <h2 className="header01">日本鉄道施設協会誌　電子版について</h2>
              <p className="mb30">日本鉄道施設協会誌　電子版は会員専用ページです（外部サイトへ移動します）。<br />
                ログインIDとパスワードの詳細につきましては、日本鉄道施設協会誌に記載してあります。<br />
                ※当サイトでログイン済みでしたら、外部サイトでは自動ログインとなります。</p>
              <div className="aC"><a href={url} target="_blank" className="btn_rounded" rel="noopener noreferrer">ログインページへ</a></div>
            </section>

            <section className="contents_block">
              <h2 className="header01">日本鉄道施設協会誌　電子版（お試し版）</h2>
              <p className="mb30">非会員の方も、こちらからお試し版をご覧いただけます。<br />
                （お試し版ログインパスワードは、アカウント名：abc123 ／ パスワード：jrcea123 とご入力ください）</p>
              <div className="aC"><a href="https://jrcea.libra.jpn.com/#/login" target="_blank" className="btn_rounded" rel="noopener noreferrer">お試し版を表示</a></div>
            </section>

          </div>
        </div>
      </>
    )
  }
}

export default Emag